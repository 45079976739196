import React from "react";
import { MapContainer, TileLayer, Marker, ZoomControl } from 'react-leaflet'
import './Carte.scss';

function Carte({bloc}) {
  const re=/POINT \((\d+\.\d+) (\d+\.\d+)\)/;
  const [lat,ln] = bloc ? bloc.address.match(re).filter((o,i)=>i>0) : [43.8, 2];
  return <div className="carte">
  <div className="carte-inner">
  <MapContainer className="carte-map" center={[ln,lat]} zoom={bloc.zoom} zoomControl={false}>
    <ZoomControl position='topright'/>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={[ln,lat]}>
    </Marker>
  </MapContainer>
  </div>
  </div>;
}
export default Carte;
