import React, { useContext } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import { Link } from "react-router-dom";

import './Logo.scss'

function Logo({id}) {
  const { t, removeScrollY } = useContext(SettingsContext);
  return <div className='logo'>
    <Link to="/" onClick={()=>removeScrollY('/')}>
    <div className='sitename'>
      <div className="nom">{t('nom-du-site')}</div>
      <div className="soustitre">{t('soustitre-site','texte')}</div>
    </div>
    <div className='pathname'>{t('ville')}</div>
    </Link>
  </div>;
}
export default Logo;
