import { gql } from '@apollo/client';
const queries={
  article: gql`
    query Article($id: GraphQLStringOrFloat){
        siblings: articles(filter: {rubrique: { articles: { id : {_eq : $id}}}}, limit:-1) {
          id
          name
          slugs (sort: "-date_created") {
            slug
          }
        }
        articles(filter: { id : { _eq: $id}}, limit:-1) {
          id
          name
          soustitre
          intro
          texte
          lieu
          date_debut
          date_fin
          texte_documents
          intervenants
          generique
          ressources
          translations {
            languages_code {
              code
              name
            }
            name
            soustitre
            intro
            texte
            lieu
          }
          images(sort: ["sort"]) {
            id
            directus_files_id {
              id
              width
              height
              credits
            }
            sort
          }
          documents(sort: ["sort"]) {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          rubrique {
            id
            type
            parent {
              id
            }
          }
          blocs(sort: ["sort"]) {
            id
            sort
            item {
              ... on bloc_image {
                images (sort:["sort"]) {
                  id
                  directus_files_id {
                    id
                    width
                    height
                    credits
                  }
                }
                size
              }
              ... on bloc_texte {
                texte
              }
              ... on bloc_carte {
                address
                title
                desc
                zoom
                translations {
                  languages_code {
                    code
                    name
                  }
                  title
                  desc
                }
              }
              ... on bloc_document {
                texte_documents
                translations {
                  languages_code {
                    code
                    name
                  }
                  texte_documents
                }
                documents {
                  id
                  directus_files_id {
                    id
                    title
                    filesize
                    type
                  }
                }
              }
            }
          }
        }
    }
  `,
  subroutes:gql`
    query SubRoutes($id: GraphQLStringOrFloat){
        rubriques(filter: { status : { _eq: "Published"}, parent: { id: {_eq: $id}}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          slugs (sort: "-date_created") {
            slug
          }
        }
        articles(filter: { status : { _eq: "Published"}, rubrique: { id: {_eq: $id}}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          slugs (sort: "-date_created") {
            slug
          }
          rubrique {
            id
            parent {
              id
            }
          }
        }
    }
  `,
  actualites:gql`
    query Actualites($hier: String!){
      actualites: articles(filter: { status : { _eq: "Published"}, _or:[
        {actualite: { _eq: true}},
        {date_fin: { _gte: $hier}},
      ]}, sort:["actualite","date_fin","sort"], limit:-1) {
        id
        name
        soustitre
        intro
        date_debut
        date_fin
        horaire
        lieu
        images(sort: ["sort"]) {
          directus_files_id {
            id
            width
            height
          }
          sort
        }
        translations {
          languages_code {
            code
            name
          }
          name
          soustitre
          intro
          lieu
        }
        rubrique {
            id
            name
            slugs (sort: "-date_created") {
              slug
            }
            parent {
              id
            }
        }
        slugs (sort: "-date_created") {
          slug
        }
      }
    }
  `,
  subroutesroot:gql`
    query Routes{
        actualites: articles(filter: { status : { _eq: "Published"}, date_fin: { _gte: "$NOW"}}, limit:-1) {
          id
          name
          soustitre
          images (sort:["sort"]) {
            directus_files_id {
              id
              width
              height
            }
          }
          translations {
            languages_code {
              code
              name
            }
            name
            soustitre
          }
          rubrique {
              id
              name
              slugs (sort: "-date_created") {
                slug
              }
              parent {
                id
              }
          }
          slugs (sort: "-date_created") {
            slug
          }
        }
        rubriques(filter: { status : { _eq: "Published"}, parent: { id: {_null:true}}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          slugs (sort: "-date_created"){
              slug
          }
          rubriques(filter: { status: { _eq: "Published" }}, limit:-1) {
            id
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
            slugs (sort: "-date_created") {
              slug
            }
          }
          articles(filter: { status: { _eq: "Published" }, index : {_eq:true}}, limit:-1) {
            id
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
            index
            slugs (sort: "-date_created") {
              slug
            }
            rubrique {
              id
              parent {
                id
              }
            }
          }
        }
        articles(filter: { status : { _eq: "Published"}, rubrique: { id: {_null:true}}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          rubrique {
            id
            parent {
              id
            }
          }
          slugs (sort: "-date_created") {
            slug
          }
        }
    }
  `,
  rubrique:gql`
    query Rubrique($id: GraphQLStringOrFloat){
        rubriques(filter: { status: { _eq: "Published" }, id : { _eq: $id } }, limit:-1) {
          id
          name
          texte
          type
          translations {
            languages_code {
              code
              name
            }
            name
            texte
          }
          parent(filter: { status : { _eq: "Published"}}, limit:-1) {
            id
          }
          slugs (sort: "-date_created") {
            slug
          }
          articles(filter: { status: { _eq: "Published" }}, limit:-1) {
            id
            name
            soustitre
            date
            translations {
              languages_code {
                code
                name
              }
              name
              lieu
              soustitre
            }
            index
            date_debut
            date_fin
            lieu
            images (sort:["sort"]) {
              directus_files_id {
                id
                width
                height
              }
            }
            rubrique {
              id
              type
              parent {
                id
                type
              }
            }
            slugs (sort: "-date_created") {
              slug
            }
          }
          rubriques(filter: { status: { _eq: "Published" }}, limit:-1) {
            id
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
            slugs (sort: "-date_created") {
              slug
            }
            rubriques(filter: { status: { _eq: "Published" }}, limit:-1) {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
              slugs (sort: "-date_created") {
                slug
              }
            }
            articles(filter: { status: { _eq: "Published" }, index : {_eq:true}}, limit:-1) {
              id
              name
              lieu
              translations {
                languages_code {
                  code
                  name
                }
                name
                lieu
              }
              slugs (sort: "-date_created") {
                slug
              }
              rubrique {
                id
                parent {
                  id
                }
              }
            }
          }
        }
    }
  `,
  menu:gql`
    query Menu{
      rubriques(filter: { status: { _eq: "Published" }, parent: {id: {_null:true}}}, sort: "sort", limit:-1) {
        id
        name
        translations {
          languages_code {
            code
            name
          }
          name
        }
        slugs (sort: "-date_created") {
          slug
        }
      }
    }
  `,
  podcasts:gql`
    query Podcasts{
      articles(filter: { status: { _eq: "Published" }, rubrique: {type: {_eq:"podcasts"}}}, sort: "sort", limit:-1) {
        id
        son {
          id
        }
      }
    }
  `,
  langswitch:gql`
    query LangSwitch{
        languages {
          code
        }
    }
  `,
  settings:gql`
    query Settings{
      interface {
        key
        value
        texte
        translations {
          languages_id {
            code
            name
          }
          value
          texte
        }
      }
      settings {
        color
      }
      rubriques(filter: { status : { _eq: "Published"}}, limit:-1) {
        id
        name
        translations {
          languages_code {
            code
            name
          }
          name
        }
        parent(filter: { status : { _eq: "Published"}}, limit:-1) {
            id
        }
        rubriques(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          slugs (sort: "-date_created") {
            slug
          }
        }
        articles(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
          slugs (sort: "-date_created") {
            slug
          }
          rubrique {
            id
            parent {
              id
            }
          }
        }
        slugs (sort: "-date_created") {
          slug
        }
      }
      articles(filter: { status : { _eq: "Published"}}, limit:-1) {
        id
        name
        texte
        soustitre
        intro
        translations {
          languages_code {
            code
            name
          }
          name
          texte
          soustitre
          intro
        }
        blocs(sort: ["sort"]) {
          id
          sort
          item {
            ... on bloc_texte {
              texte,
              translations {
                languages_code {
                  code
                  name
                }
                texte
              }
            }
          }
        }
        rubrique {
            id
            name
            slugs (sort: "-date_created") {
              slug
            }
            parent {
              id
            }
        }
        slugs (sort: "-date_created") {
          slug
        }
      }
    }
  `,
}
export default queries;
