import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Misc/SlugLink';
import Hidden from '@material-ui/core/Hidden';
import { Link } from "react-router-dom";

import './MainMenu.scss'

function MainMenu({id,mobile=false}) {
  const { updateLoadState,fetchedList,translate,ariane,removeScrollY } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.menu);
  const rubriques=data ? data.rubriques : [];
  const uuid=`MainMenu`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  return <div className='main-menu'>
    <ul className={'main-menu-inner'+(mobile ? ' mobile' : '')}>
      <Hidden lgUp><li><Link to="/" onClick={()=>removeScrollY('/')} className="actu-btn big"></Link></li></Hidden>
      {rubriques.map((r,i)=>{
        return <li key={r.id} className={ariane.findIndex((o)=>o.__typename==='rubriques' && o.id===r.id)!==-1 ? 'active' : null}><SlugLink item={r}>{translate(r,'name')}</SlugLink></li>
      })}
    </ul>
  </div>
  ;
}
export default MainMenu;
