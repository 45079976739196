import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import './Article.scss'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import ArticleExposition from 'components/Article/ArticleExposition';
import ArticlePodcast from 'components/Article/ArticlePodcast';
import ArticleGeneral from 'components/Article/ArticleGeneral';

function Article({id}) {
  const { updateLoadState,fetchedList,translate,lang } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.article,{
    variables: { id : parseInt(id) },
  });
  const uuid=`article${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const article=data ? data.articles[0] : null;
  const siblings=data ? data.siblings : [];
  useEffect(()=>document.title = article ? translate(article,'name') : '',[article,lang,translate]);
  if (article) {
    if (article.rubrique.type==='exposition') {
      return <ArticleExposition article={article}/>
    } else if (article.rubrique.type==='podcasts') {
      return <ArticlePodcast article={article} siblings={siblings}/>
    } else {
      return <ArticleGeneral article={article}/>
    }
  } else return <div><div className="bottom-padding"/></div>;
}
export default Article;
