import React, { useEffect } from "react";
import './Gallery.scss';
import Image from 'components/misc/Image';
import Utils from 'utils/Utils';

function Gallery({id, images, onClose, onChange}) {
  let classes='gallery';
  if (id)  classes+=' on';
  const idx=images.findIndex((o)=>o.directus_files_id.id===id);
  const next=idx<images.length-1 ? images[idx+1].directus_files_id.id : images[0].directus_files_id.id;
  const prev=idx>1 ? images[idx-1].directus_files_id.id : images[images.length-1].directus_files_id.id;
  const goNext=(e)=>{
    e.stopPropagation();
    const w=e.target.clientWidth;
    const x=e.clientX;
    if (x>w/2) onChange(next);
    else onChange(prev);
  }
  useEffect(()=>{
    const root = document.getElementsByTagName('html')[0];
    if (id) root.style.overflow='hidden';
    else root.style.overflow='auto';
    return ()=>{
      const root = document.getElementsByTagName('html')[0];
      root.style.overflow='auto';
    }
  },[id]);
  return <div className={classes}>
    {id && <Image
    src={`/assets/${id}?key=hd`}
    onClick={goNext}
    width={Utils.computeImgSize({img:images[idx].directus_files_id}).width}
    height={Utils.computeImgSize({img:images[idx].directus_files_id}).height}
    />}
    <div className='gallery-close' onClick={onClose}></div>
    {images[idx] && images[idx].directus_files_id.credits && <div className='gallery-credits'>© {images[idx].directus_files_id.credits}</div>}
  </div>
}
export default Gallery;
