import React, { useState, useEffect, useContext, useRef } from "react";
import Pagination from 'components/misc/Pagination';
import { SettingsContext } from 'Providers/SettingsProvider';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Conf from 'utils/Conf';
import SlugLink from 'components/Misc/SlugLink';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { DateTime } from 'luxon';

import './Recherche.scss'
const match=(article,filter)=>{
  if (article.name && article.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
  if (article.texte && article.texte.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
  if (article.soustitre && article.soustitre.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
  if (article.intro && article.intro.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
  if (article.translations.length>0){
    for(let i=0;i<article.translations.length;i++){
      if (article.translations[i].name && article.translations[i].name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
      if (article.translations[i].texte && article.translations[i].texte.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
      if (article.translations[i].soustitre && article.translations[i].soustitre.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
      if (article.translations[i].intro && article.translations[i].intro.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
    }
  }
  for(let i=0;i<article.blocs.length;i++){
    if (article.blocs[i].item.__typename==='bloc_texte') {
      if (article.blocs[i].item.texte && article.blocs[i].item.texte.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
      for(let j=0;j<article.blocs[i].item.translations.length;j++){
        if (article.blocs[i].item.translations[j].texte && article.blocs[i].item.translations[j].texte.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())!==-1) return true;
      }
    }
  }
}
function Recherche() {
  const { updateLoadState, fetchedList,translate, t, data:mainData, getArianeFromItem, screenSize} = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.actualites,{
    variables: {
      hier:DateTime.now().minus({ days: 1 }).toISODate(),
    },
  });
  const actualites=data ? data.actualites : [];
  const input=useRef(null);
  const uuid=`home`;
  const [ page,setPage ] = useState(1);
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const [ on, setOn ]=useState(false);
  const [ filter, setFilter ]=useState('');
  const handleChange=(e)=>{
    setFilter(e.target.value);
  }
  useEffect(()=>{
    const root = document.getElementsByTagName('html')[0];
    if (on) {
      root.style.overflow='hidden';
      if (filter==='' && input.current) input.current.focus();
    }
    else root.style.overflow='auto';
    return ()=>{
      const root = document.getElementsByTagName('html')[0];
      root.style.overflow='auto';
    }
  },[on,filter]);
  let classes2='recherche-overlay';
  if (on)  classes2+=' on';
  const backgroundImage=actualites && actualites.length>0 && actualites[0].images[0] ? `url(${Conf.apiUrl}/assets/${actualites[0].images[0].directus_files_id.id}?key=hd)` : null;
  const results=mainData && mainData.articles && filter.length>0 ? mainData.articles.filter((o)=>match(o,filter)): [];
  const itemPerPage=['xs','sm','md'].indexOf(screenSize)===-1 ? 6 : 4;
  const nb=Math.ceil(results.length/itemPerPage);
  useEffect(()=>setPage(1),[filter,setPage])
  return <>
    <div className={"recherche"+(on ? " off": '')} onClick={()=>setOn(true)}>
      {t('rechercher')}{screenSize!=='xs' && <span>__________________</span>}
    </div>
    <div className={"recherche-results"+(on ? " on": '')}>
      <div className="recherche-mask0"/>
      <div className="recherche-mask" style={{backgroundImage}}/>
      <div className="recherche-mask2"/>
      <div className={classes2}>
        <div className='recherche-close' onClick={()=>setOn(false)}></div>
        <div className="main-input">
          <input type="text" value={filter} onChange={handleChange} ref={input}/>
        </div>
        <Container maxWidth='xl'>
          <Grid container spacing={3} className="results">
            {results.map((article,i)=>{
              if (i>=(page-1)*itemPerPage && i<page*itemPerPage) {
                let ariane=getArianeFromItem(article);
                return <Grid item xs={12} sm={6} lg={4} key={article.id} onClick={()=>setOn(false)}><SlugLink item={article}>
                  <div className="ariane local">
                    {ariane.filter((o,i)=>i<ariane.length-1).map((item,i)=><React.Fragment key={i}>
                      <span>{i>0 && ' >'}{translate(item,'name')}</span>
                    </React.Fragment>)}
                  </div>
                  <div>{article.name}</div>
                </SlugLink></Grid>;
              }
              return null;
            })}
          </Grid>
          <div className="spacer"/>
          {results.length>itemPerPage && <Pagination page={page} nb={nb} onPageChange={(p)=>setPage(p)}/>}
        </Container>
      </div>
    </div>
  </>;
}
export default Recherche;
