import React, { useState,  useEffect, useContext } from "react";
import Ariane from 'components/misc/Ariane';
import Recherche from 'components/Recherche';
import './Footer.scss'
import {
  useLocation,
} from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';
import AudioPlayer from 'components/AudioPlayer';
import _ from 'lodash';

function Footer({id}) {
  const { ready, appState, setAppState, screenSize } = useContext(SettingsContext);
  const { pathname,state } = useLocation();
  const [ footerState, setFooterState ] = useState({hidden:false,mini:true})
  const hide=['/'].indexOf(pathname)!==-1;
  useEffect(()=>{
    if (state && state.item) {
      let interval=null;
      const handleScroll=_.debounce((e)=>{
        if (document.querySelector('.top-container') && window.scrollY>document.querySelector('.top-container').scrollHeight-window.innerHeight-100) {
          setFooterState((state)=>{return {...state,mini:false}});
        } else {
          setFooterState((state)=>{return {...state,mini:true}});
        }
        if (pathname.startsWith('/expositions/') && state && state.item && state.item.__typename==='articles' && window.scrollY<100) {
          setFooterState((state)=>{return {...state,hidden:true}});
        } else {
          setFooterState((state)=>{return {...state,hidden:false}});
        }
      },10);
      window.addEventListener('scroll',handleScroll);
      window.addEventListener('resize',handleScroll);
      interval=setInterval(handleScroll,100);
      setTimeout(()=>{
        console.log('clear interval');
        clearInterval(interval);
      },5000);
      return ()=>{
        clearInterval(interval);
        window.removeEventListener('scroll',handleScroll);
        window.removeEventListener('resize',handleScroll);
      }
    }
  },[setFooterState,pathname,state,ready,appState.footerUpdate]);
  return <div className={'footer'+(footerState.hidden || hide ? ' hide' : '')+(footerState.mini ? ' mini' : '')}>
    <Ariane lastOnly={screenSize==='xs'}/>
    <Recherche/>
    {appState.currentPlayer && <AudioPlayer {...appState.currentPlayer} onClose={()=>setAppState((state)=>{return{...state,currentPlayer:null}})}/>}
  </div>;
}
export default Footer;
