import React, { useContext,useEffect,useRef,useMemo,useState } from "react";
import './Actualites.scss'
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Queries from 'utils/Queries';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { SettingsContext } from 'Providers/SettingsProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlugLink from 'components/Misc/SlugLink';
import Image from 'components/misc/Image';
import Utils from 'utils/Utils';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { DateTime } from 'luxon';
import Hidden from '@material-ui/core/Hidden';
SwiperCore.use([Navigation, Autoplay, A11y]);

function Actualites() {
  const { updateLoadState,fetchedList,translate,appStarted } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.actualites,{
    variables: {
      hier:DateTime.now().minus({ days: 1 }).toISODate(),
    },
  });
  const actualites=useMemo(()=>data ? data.actualites : [],[data]);
  const uuid=`home`;
  const [ hover,setHover ] = useState(false);
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const handleTransitionEnd=(swiper)=>{
    swiper.params.speed = 1500;
    swiper.autoplay.start();
  };
  const handleTouchStart=(swiper)=>{
    swiper.params.speed = 300;
  };
  const swiperRef=useRef(null);
  const handleSwiper=(swiper)=>{
    swiperRef.current=swiper;
    if (!appStarted) {
      swiper.autoplay.stop();
      swiper.disable();
    }
    if (actualites.length===1) swiper.disable();
  }
  useEffect(()=>{
    if (swiperRef.current!==null) {
      const swiper=swiperRef.current;
      if(actualites.length>1 && appStarted) {
        swiper.enable();
        swiper.autoplay.start();
      } else {
        swiper.autoplay.stop();
        swiper.disable();
      }
    }
  },[appStarted,actualites])
  return actualites ? <div className="actualites swiper-top-container">
    <div className="swiper-top-container-inner">
    {actualites.length>0 ?
    <Swiper
    speed={1500}
    loop
    autoplay={{
      delay: 5000,
    }}
    onTransitionEnd={handleTransitionEnd}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchStart}
    onSwiper={handleSwiper}
    >
      {actualites.map((actualite,i)=>{
          return <SwiperSlide key={actualite.id}>
            <div className={"actualite-image"+(hover ? ' hover' : '')}>
              {actualite.images[0] && <Image
              src={`/assets/${actualite.images[0].directus_files_id.id}?key=hd`}
              width={Utils.computeImgSize({img:actualite.images[0].directus_files_id}).width}
              height={Utils.computeImgSize({img:actualite.images[0].directus_files_id}).height}
              size={'cover'}
              />}
            </div>
            <SlugLink item={actualite}>
              <div className={"actualite-desc"+(hover ? ' hover' : '')} onMouseEnter={()=>setHover(true)}  onMouseLeave={()=>setHover(false)}>
                {actualite.date_debut && actualite.date_fin && <div className="article-dates">{DateTime.fromISO(actualite.date_debut).toFormat('d.LL')} – {DateTime.fromISO(actualite.date_fin).toFormat('d.LL')}</div>}
                <div className="article-lieu">{actualite.horaire} {actualite.lieu}</div>
                <h3 className="article-titre"><i>{translate(actualite,'name')}</i></h3>
                <h3 className="article-artiste">{translate(actualite,'soustitre')}</h3>
                <Hidden xsDown>
                <Container className="article-chapo" maxWidth='lg'>
                  {Utils.parseWithLinks(Utils.truncateString(translate(actualite,'intro'),50))}
                </Container>
                </Hidden>
              </div>
            </SlugLink>
        </SwiperSlide>;
      })}
    </Swiper>
    : ''}
    </div>
    </div> : '';
}
export default Actualites;
