import React, { useState, useRef, useLayoutEffect, useMemo } from "react";
import './Mosaique.scss'
import Utils from 'utils/Utils';
import Image from 'components/misc/Image';
import Gallery from 'components/misc/Gallery';

let interval=0;
let i=0;
let deltas=[];
const getNumber=()=>parseInt(Math.max(-5,Math.min(5,(0.5-Math.random())*30)));
const getDeltas=(i)=>{
  if (deltas[i]) return deltas[i];
  else {
    deltas[i]={x:getNumber(),y:getNumber(),scale:0.9-Math.random()*0.2};
    return deltas[i];
  }
}

function Mosaique({images,wide=false}) {
  const [galleryId,setGalleryId ] = useState(null);
  const mainRef=useRef(null);
  const showGallery=(id)=>{
    setGalleryId(id);
  }
  const handleClose=()=>{
    setGalleryId(null);
  }
  const credits=useMemo(()=>{
    const tab=images.reduce((acc,image)=>{
      if (image.directus_files_id.credits && acc.indexOf('© '+image.directus_files_id.credits)===-1) {
        return [...acc,'© '+image.directus_files_id.credits]
      }
      return acc;
    },[]);
    return tab.join(',');
  },[images]);
  useLayoutEffect(()=>{
    const tourne=()=>{
      if (mainRef.current) {
        let nb=2;
        let nbLines=2;
        const inner=mainRef.current.childNodes[0];
        let step=Math.min(250,wide ? window.innerWidth*30/100 : window.innerWidth*15/100);
        if (window.innerWidth>768 && step<180) nbLines=3;
        if(inner.clientHeight>nbLines*(step+15)){
          while(inner.clientHeight>Math.round(nb*(step+15))){
            nb++;
          }
        };
        let steps=nb-nbLines;
        if (i<steps) i++
        else i=0;
        inner.style.transform="translate3d(0,"+(-i*(step+15))+"px,0)";
        mainRef.current.style.height=(nbLines*step+(nbLines-1)*15)+'px';
      }
    }
    clearInterval(interval);
    interval=setInterval(tourne,3000);
    i=0;
    tourne();
    window.addEventListener('resize',tourne);
    return ()=>{
      clearInterval(interval);
      window.removeEventListener('resize',tourne);
    }
  },[wide]);
  const step=Math.min(250,wide ? window.innerWidth*30/100 : window.innerWidth*15/100);
  return images.length>0 ? <>
  <div className={'article-mosaique'+(wide ? ' wide' : '')} ref={mainRef}>
    <div className="article-mosaique-inner">
    {images.length>0 && images.map((f,i)=>{
      let deltas=getDeltas(i);
      return <div key={f.id} className="article-images-wrapper">
      <div className="article-images" style={{transform:"translate3d("+deltas.x+"px,"+deltas.y+"px,0)"}}>
        <Image
          src={`/assets/${f.directus_files_id.id}?key=mini`}
          onClick={()=>showGallery(f.directus_files_id.id)}
          width={Utils.computeImgSize({img:f.directus_files_id,h:step}).width}
          height={Utils.computeImgSize({img:f.directus_files_id,h:step}).height}
        />
      </div>
    </div>;})}
    </div>
    {images.length>1 && <Gallery id={galleryId} images={images} onClose={handleClose} onChange={setGalleryId}/>}
  </div>
  {credits && <div className="article-images-credits">
    {credits}
  </div>}
  </>: null;
}
export default Mosaique;
