import React, { useContext } from "react";
import Container from '@material-ui/core/Container';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Utils from 'utils/Utils';
import BlocTexte from 'components/Article/Blocs/Texte';
import BlocDocument from 'components/Article/Blocs/Document';
import Bloc from 'components/Article/Blocs';

function ArticleGeneral({article}) {
  const { translate, ready } = useContext(SettingsContext);
  let prevId=article ? 'texte-root-'+article.id : null;
  return article ? <div className={'article'+(ready ? ' ready' : '')}><Container maxWidth='xl' className="article-content">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="article-generique-titre">{translate(article,'name')}</h3>
          {article.soustitre && <h3 className="article-generique-artiste">{translate(article,'soustitre')}</h3>}
        </Grid>
        <Grid item xs={12}>
          <div className="article-intro">{Utils.parseWithLinks(translate(article,'intro'))}</div>
        </Grid>
        <Grid item xs={12} lg={6} style={{position:'relative'}}>
          <div className="article-texte" id={'texte-root-'+article.id}>{Utils.parseWithLinks(translate(article,'texte'))}</div>
          {article.blocs.map((bloc,i)=>{
            if (bloc.item.__typename==="bloc_texte") prevId='texte-'+i+'-'+article.id;
            if (bloc.item.__typename==="bloc_texte") return <BlocTexte key={bloc.id} prevId={prevId} bloc={bloc.item}/>;
            return <Bloc key={bloc.id} prevId={prevId} bloc={bloc.item} idx={i}/>;
          })}
          {article.documents.length>0 && <BlocDocument bloc={article}/>}
        </Grid>
      </Grid>
    </Container>
    <div className="bottom-padding"/>
    </div> : null;
}
export default ArticleGeneral;
