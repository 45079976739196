import { useCallback, useContext, useRef, useEffect, useMemo } from 'react';
import { SettingsContext } from 'Providers/SettingsProvider';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Conf from 'utils/Conf';

function Playlist(){
    const players=useRef([]);
    const { updateLoadState, fetchedList, appState, setAppState } = useContext(SettingsContext);
    const { networkStatus,data,refetch } = useQuery(Queries.podcasts);
    const podcasts=useMemo(()=>data ? data.articles : [],[data]);
    const uuid=`Podcasts`;
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const currentTimeCache=useRef({});
    const playingCache=useRef({});
    const getPlayer=useCallback((uuid)=>{
      return players.current.find((o)=>o.uuid===uuid);
    },[]);
    useEffect(()=>{
      const play=(uuid)=>{
        console.log('play');
        players.current.forEach((player)=>{
            if (player.uuid!==uuid) player.audio.pause();
        });
        const player=getPlayer(uuid);
        player.audio.play();
      }
      const pause=(uuid)=>{
        console.log('pause');
        const player=getPlayer(uuid);
        player.audio.pause();
      }
      const seek=(uuid, time)=>{
        console.log('seek');
        const player=getPlayer(uuid);
        player.audio.currentTime=time;
        if (player.audio.paused) play(uuid);
      }
      Object.keys(appState.playersControls).forEach((uuid) => {
        const pState=appState.playersControls[uuid];
        const player=getPlayer(uuid);
        if(player){
          const cachedPlaying=playingCache.current[uuid];
          if (pState.playing===true && cachedPlaying!==pState.playingIdx && player.audio.paused) play(uuid);
          if (pState.playing===false && cachedPlaying!==pState.playingIdx && !player.audio.paused) pause(uuid);
          playingCache.current[uuid]=pState.playingIdx;
          const cachedCurrentTime=currentTimeCache.current[uuid];
          if (!isNaN(pState.currentTime) && cachedCurrentTime!==pState.currentTimeIdx) seek(uuid,pState.currentTime);
          currentTimeCache.current[uuid]=pState.currentTimeIdx;
        }
      });
    },[appState.playersControls,getPlayer])
    const handlePlay=useCallback((uuid)=>{
      setAppState((state)=>{
        const pState=state.playersStates[uuid];
        return pState && !pState.playing ?
          {...state,playersStates:{...state.playersStates,[uuid]:{...pState,playing:true,ended:false}}}
          : state ;
      });
    },[setAppState]);
    const handlePause=useCallback((uuid)=>{
      setAppState((state)=>{
        const pState=state.playersStates[uuid];
        return pState && pState.playing ?
          {...state,playersStates:{...state.playersStates,[uuid]:{...pState,playing:false,ended:false}}}
          : state ;
      });
    },[setAppState]);
    const handleEnded=useCallback((uuid)=>{
      const player=getPlayer(uuid);
      player.audio.currentTime=0;
      setAppState((state)=>{
        const pState=state.playersStates[uuid] || {};
        return {...state,playersStates:{...state.playersStates,[player.uuid]:{...pState,
          playing:false,
          currentTime:0,
          position:0,
          ended:true,
        }}}
      });
    },[setAppState,getPlayer]);
    const handleTimeupdate=useCallback((e,uuid)=>{
        const player=getPlayer(uuid);
        setAppState((state)=>{
          const pState=state.playersStates[uuid] || {};
          return {...state,playersStates:{...state.playersStates,[player.uuid]:{
            ...pState,
            currentTime:e.target.currentTime,
            position:e.target.currentTime/e.target.duration,
            ended:false,
          }}}
        });
    },[setAppState,getPlayer]);
    const handleLoadedMetadata=useCallback((e,uuid)=>{
        const player=getPlayer(uuid);
        player.duration=e.target.duration;
        setAppState((state)=>{
          const pState=state.playersStates[uuid] || {};
          return {...state,playersStates:{...state.playersStates,[player.uuid]:{
            ...pState,
            playing:false,
            duration:e.target.duration,
            currentTime:e.target.currentTime,
            position:e.target.currentTime/e.target.duration,
            ended:false,
            loadedmetadata:true,
          }}}
        });
    },[setAppState,getPlayer]);
    const addSound=useCallback((uuid,url)=>{
        const player={uuid,url};
        player.audio=document.createElement('audio');
        player.audio.setAttribute('id',player.uuid);
        player.audio.setAttribute('preload','metadata');
        player.audio.addEventListener('play',(e)=>handlePlay(player.uuid));
        player.audio.addEventListener('pause',(e)=>handlePause(player.uuid));
        player.audio.addEventListener('ended',(e)=>handleEnded(player.uuid));
        player.audio.addEventListener('timeupdate',(e)=>handleTimeupdate(e,player.uuid));
        player.audio.addEventListener('loadedmetadata',(e)=>handleLoadedMetadata(e,player.uuid));
        player.audio.setAttribute('src',url);
        players.current.push(player);
        document.body.appendChild(player.audio);
    },[handlePlay,handlePause,handleEnded,handleTimeupdate,handleLoadedMetadata]);
    useEffect(()=>{
      podcasts.forEach((podcast) => {
        const idx=players.current.findIndex((o)=>o.uuid===podcast.id);
        if (idx===-1) addSound(podcast.id,Conf.apiUrl+'/assets/'+podcast.son.id);
      });
    },[podcasts,addSound]);
    return null;
};
export default Playlist;
