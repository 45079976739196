import React, {useState,useEffect,useRef} from "react";
import './Image.scss'
import Conf from 'utils/Conf';

function Image({src,alt='',size='contain',align='center',onClick=()=>null,width=null,height=null}) {
  const [loaded,setLoaded] = useState(false);
  const [dim,setDim] = useState({width,height});
  const imgRef=useRef(null);
  const handleLoaded=()=>{
    setLoaded(true);
  };
  useEffect(()=>{
    if (imgRef.current) {
      let img=imgRef.current;
      img.addEventListener('load',handleLoaded);
      return ()=>{
        img.removeEventListener('load',handleLoaded);
      }
    }
  },[setLoaded])
  useEffect(()=>{
    const handleResize=()=>{
      if (imgRef.current && !isNaN(width) && !isNaN(height)) {
        let h=imgRef.current.clientHeight;
        setDim({width:h*width/height,height:h});
      }
    };
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[width,height,setDim]);
  return <img
  className={'myimg'+(align==='left' ? ' left' : '')+(loaded ? ' loaded' : '')+(size==='cover' ? ' img-cover' : ' img-contain')}
  onClick={onClick}
  ref={imgRef}
  alt={alt}
  src={Conf.apiUrl+src}
  height={dim.height}
  width={dim.width || null}
  />;
}
export default Image;
