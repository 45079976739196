import React, {useContext} from "react";
import './MenuArticles.scss'
import SlugLink from 'components/Misc/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';

function MenuArticles({rubrique}) {
  const { translate } = useContext(SettingsContext);
  let index=[];
  if (rubrique.articles && rubrique.articles.length>0) {
    index=rubrique.articles.filter(o => o.index);
  }
  return <>
          {index.length>0 && <div className="menu-articles">
            {index.map((r,i)=>{
              return r.index ? <div key={r.id} className="menu-articles-item">
                <div className="rubrique-menu-articles">
                  <SlugLink item={r}>{translate(r,'name')}</SlugLink>
                </div>
              </div> : '';
            })}
          </div>}
        </>;
}
export default MenuArticles;
