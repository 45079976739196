import React, { useState, createContext, useEffect, useMemo, useCallback } from "react";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import {
  useLocation,
} from "react-router-dom";
import Utils from 'utils/Utils';
import tinycolor from "tinycolor2";
export const SettingsContext = createContext({});

const doUpdateLoadState=(key,networkStatus,setLoadlist, setFetchedList)=>{
    setFetchedList((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus===1) setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx===-1) return [...list,key];
        return list;
    });
    if (networkStatus!==1) setTimeout(()=>setLoadlist((list)=>{
        if (!Array.isArray(list)) list=[];
        let idx=list.indexOf(key);
        if (idx!==-1) {
            let res=list.reduce((acc,l,i)=>(i!==idx) ? [...acc,l]: acc ,[]);
            return res;
        }
        return list;
    }),500);
}
const getParentId=(item) => {
  if(item.__typename==='articles') return item.rubrique ? item.rubrique.id : null
  if(item.__typename==='rubriques') return item.parent ? item.parent.id : null
  return null;
}
const getPathFromData=(item, data) => {
    const rubriques = data ? data.rubriques : [];
    const articles = data ? data.articles : [];
    const itemModel = Boolean(item) ? (item.__typename==='articles' ? articles.find((o)=>o.id===item.id) : (
        item.__typename==='rubriques' ? rubriques.find((o)=>o.id===item.id) : {...item}
    )) : null ;
    let maxDepth=5;
    let currentDepth=0;
    let currentRub=itemModel && getParentId(itemModel);
    let path='/';
    const itemSlug=itemModel ? (itemModel.slugs && itemModel.slugs[0] ? itemModel.slugs[0].slug : itemModel.id) : null;
    if (itemModel && itemModel.__typename==='rubriques') path='/'+itemSlug;
    if (itemModel && itemModel.__typename==='articles') path='/'+itemSlug;
    while (currentDepth<maxDepth && currentRub!==null) {
        currentDepth++;
        let rub=currentRub;
        let parent=rubriques.find((o)=>o.id===rub);
        let slug= parent ? ( parent.slugs && parent.slugs[0] ? parent.slugs[0].slug : parent.id ) : '';
        path=`/${slug}${path}`;
        if (parent) currentRub=getParentId(parent);
    }
    return path;
}
const getArianeFromPath=(path,data)=>{
  const tab=path.split('/');
  let res=[];
  if (path==='/actualites') {
    return [{custom:true,key:'actualites'}]
  }
  tab.forEach((item, i) => {
    if (data && i>0) {
      let item={name:'',id:''};
      if (i===tab.length-1) {
        //est-ce un article ?
        data.articles.forEach((art) => {
          art.slugs.forEach((slug) => {
            if (slug.slug===tab[i]) {
              item=art;
            }
          });
        });
      }
      data.rubriques.forEach((rub) => {
        rub.slugs.forEach((slug) => {
          if (slug.slug===tab[i]) item=rub;
        });
      });
      res.push(item);
    }
  });
  return res;
}
let doneTimeout=0;
const SettingsProvider = ({clientSystem,width,children})=>{
    const { pathname } = useLocation();
    const [ lang, setLang ] = useState('fr');
    const [ screenSize, setScreenSize ] = useState("xxl");
    const [ ariane, setAriane ] = useState([]);
    const [ mustload, setMustload ] = useState(true);
    const [ ready, setReady ] = useState(false);
    const [ done, setDone ] = useState(false);
    const [ appState, setAppState ] = useState({
      menuFolded:false,
      playersStates:{},
      playersControls:{},
      playlistControls:{},
      footerUpdate:0,
      currentYear:{},
    });
    const [ introDone, setIntroDone ] = useState(false);
    const [ loadlist, setLoadlist ] = useState(null);
    const [ fetchedList, setFetchedList ] = useState([]);
    const [ currentRub, setCurrentRub ] = useState(1);
    const { networkStatus,data,refetch } = useQuery(Queries.settings);
    const updateLoadState=useMemo(()=>
      (key,networkStatus)=>doUpdateLoadState(key,networkStatus,setLoadlist,setFetchedList),
      [setLoadlist,setFetchedList]
    );
    const uuid='settings';
    useEffect(()=>{
      doneTimeout=setTimeout(()=>setDone(true),3000);
      return ()=>{
        clearTimeout(doneTimeout);
      }
    },[setDone]);
    useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
    useEffect(()=>{
      if(fetchedList.indexOf(uuid)===-1){
        console.log('fetch',uuid);
        refetch();
      }
    },[refetch,fetchedList,uuid]);
    const getPath=useCallback((item)=>getPathFromData(item,data),[data]);
    const getArianeFromItem=useCallback((item)=>getArianeFromPath(getPath(item),data),[getPath,data]);
    const trads=data ? data.interface.reduce((acc,item,i)=>{
        const key=item.key;
        const value=item.value;
        const texte=item.texte;
        const translations=item.translations.reduce((accTr,tr,j)=>{
            const lang=tr.languages_id.code;
            const valueTr=tr.value;
            const textTr=tr.texte;
            return {[lang]:{value:valueTr,texte:textTr},...accTr};
        },{});
        return {[key]:{value,texte,translations},...acc};
    },{}) : {};
    const t=(key,type='value')=>{
        const tr=trads[key] ? ( trads[key].translations[lang] || trads[key] ) : {value:null, texte:null};
        return  tr[type] || '';
    };
    const translate=(item,key)=>{
        return Utils.translate(item,lang,key);
    };
    useEffect(()=>{
        const resize=()=>{
            const width=window.innerWidth;
            let size="xs";
            if (width>576) size="sm";
            if (width>768) size="md";
            if (width>992) size="lg";
            if (width>1200) size="xl";
            if (width>1200) size="xl";
            if (width>1600) size="xxl";
            setScreenSize(size);
        }
        resize();
        window.addEventListener('resize',resize);
        return ()=>{
          window.removeEventListener('resize',resize);
        }
    },[setScreenSize]);
    useEffect(()=>{
        if (Array.isArray(loadlist) && loadlist.length>0) setMustload(false);
        if (!ready && (!loadlist || (Array.isArray(loadlist) && loadlist.length===0))) setReady(true);
    },[loadlist,setReady,ready]);
    useEffect(()=>{
      setMustload(true);
      setTimeout(()=>setMustload(false),100);
    },[pathname]);
    useEffect(()=>{
      setAriane(getArianeFromPath(pathname,data));
    },[pathname,data]);
    useEffect(()=>{
        if (data && data.settings) {
          const root = document.documentElement;
          //violet : #956BFE
          root.style.setProperty('--main-color', data.settings.color || "#fff");
          const colorTransparent = tinycolor(data.settings.color || "#fff").setAlpha(0.5).toString();
          root.style.setProperty('--main-color-transparent', colorTransparent || "#fff");
          const colorSemi = tinycolor(data.settings.color || "#fff").setAlpha(0.3).saturate(50).toString();
          root.style.setProperty('--main-color-semi', colorSemi || "#fff");
          const colorLight = tinycolor(data.settings.color || "#fff").lighten(5).toString();
          root.style.setProperty('--main-color-light', colorLight || "#fff");
          const colorDark = tinycolor(data.settings.color || "#fff").darken(5).toString();
          root.style.setProperty('--main-color-dark', colorDark || "#000");
        }
    },[data]);
    const removeScrollY=(pathname)=>{
      setAppState((state)=>{return{...state,pagesScrollY:{...state.pagesScrollY,[pathname]:null}}});
    };
    const loaded=!mustload && (Array.isArray(loadlist) ? loadlist.length===0 : false);
    let appStarted = data && ready && done;
    const value={
      removeScrollY,
      ariane,
      data,
      clientSystem,
      fetchedList,
      screenSize,
      trads,
      lang,
      setLang,
      t,
      translate,
      currentRub,
      setCurrentRub,
      loadlist,
      loaded,
      ready,
      pathname,
      updateLoadState,
      getPath,
      getArianeFromItem,
      appState,
      setAppState,
      introDone,
      setIntroDone,
      appStarted,
      width,
    };
    let classes="top-container";
    if (ready || pathname==="/preview") classes+=" ready";
    if (loaded) classes+=" loaded";
    let waitClasses="wait";
    if (appStarted || pathname==="/preview") waitClasses+=" ready";
    return (
        <SettingsContext.Provider value={value}>
            {ready && <div className={waitClasses}>
              <div className="wait-logo">
                <div>{t('nom-du-site')}</div>
                <div className="soustitre">{t('soustitre-site')}</div>
                <div className="ville">{t('ville')}</div>
              </div>
              <div className="status">
                <span className={'enter'+(ready ? ' on' : '')} onClick={()=>setDone(true)}>{t('entrer')}</span>
              </div>
            </div>}
            {data && <div className={classes}>{children}</div>}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
