import React, { useState, useCallback, useEffect, useContext } from "react";
import MainMenu from 'components/MainMenu';
import Logo from 'components/Logo';
import {
  useLocation,
} from "react-router-dom";
import LangSwitch from 'components/LangSwitch';
import { SettingsContext } from 'Providers/SettingsProvider';
import Hidden from '@material-ui/core/Hidden';
import { Link } from "react-router-dom";

import './Topbar.scss'

function Topbar({id}) {
  const { appState, removeScrollY } = useContext(SettingsContext);
  const { pathname,state } = useLocation();
  const [scrolled,setScrolled] = useState(false);
  const [active,setActive] = useState(false);
  const [ burger,setBurger ] = useState(false);
  const handleScroll=useCallback((e)=>{
    if (window.scrollY>50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  },[setScrolled]);
  useEffect(()=>{
    window.addEventListener('scroll',handleScroll);
    handleScroll();
    return ()=>{
      window.removeEventListener('scroll',handleScroll);
    }
  },[handleScroll]);
  useEffect(()=>{
    setActive(false);
  },[pathname,setActive]);
  const mini=(state && state.item && state.item.__typename==='articles') || scrolled;
  const hide=!active && ['/'].indexOf(pathname)!==-1;
  useEffect(()=>{
    setBurger(false);
  },[pathname,setBurger]);
  return <>
    <div className={'topbar'+(hide ? ' hide' : '')+(active ? ' active' : '')+(appState.menuFolded ? ' folded' : '')}>
    <div className={'topbar-inner'+(mini ? ' scrolled' : '')}>
      <div className='logo-container' onClick={()=>setActive((state)=>!state)}>
        <Logo/>
      </div>
      <Hidden mdDown>
      <div className='main-menu-container'>
        <MainMenu/>
      </div>
      <Link to="/" onClick={()=>removeScrollY('/')} className="actu-btn"></Link>
      </Hidden>
      <div className='lang-switch-container'>
        <LangSwitch/>
        <Hidden lgUp>
        <div className='burger-container' onClick={()=>setBurger((state)=>!state)}>
        </div>
        </Hidden>
      </div>
    </div>
    </div>
    <div className={"menu-mobile"+(burger ? ' on' : '')} onClick={()=>setBurger(false)}>
      <MainMenu mobile/>
    </div>
  </>;
}
export default Topbar;
