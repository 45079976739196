import React, { useContext, useMemo, useEffect } from "react";
import './ListeArticles.scss'
import Grid from '@material-ui/core/Grid';
import SlugLink from 'components/Misc/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';
import { DateTime } from 'luxon';
import Image from 'components/misc/Image';
import PodcastsList from 'components/Rubrique/PodcastsList';

function ListeArticles({rubrique}) {
  const { translate, screenSize, setAppState, appState } = useContext(SettingsContext);
  let largeur=rubrique.articles.length>=3 ? 4 : true;
  if (['md','lg'].indexOf(screenSize)!==-1) largeur=rubrique.articles.length>=2 ? 6 : true;
  if (['xs','sm'].indexOf(screenSize)!==-1) largeur=12;
  const currentYear=appState.currentYear['rub_'+rubrique.id] || 0;
  const setCurrentYear=(v)=>{
    setAppState((state)=>{
      return {
        ...state,
        currentYear:{
          ...state.currentYear,
          ['rub_'+rubrique.id]:v,
        }
      }
    })
  }
  useEffect(()=>{
    setAppState((state)=>{return {...state,footerUpdate:state.footerUpdate+1}});
  },[currentYear,setAppState]);
  const liste=useMemo(()=>{
    let liste=[];
    if (rubrique.articles && rubrique.articles.length>0) {
      if (rubrique.type==='exposition') {
        rubrique.articles.forEach((expo) => {
          const year=expo.date_debut ? DateTime.fromISO(expo.date_debut).toFormat('y') : '0000';
          const item=liste.find((o)=>o.year===year);
          if (item) item.liste.push(expo);
          else liste.push({year,liste:[expo]});
        });
        liste.forEach((year) => {
          year.liste.sort((a,b)=>DateTime.fromISO(a.date_debut)-DateTime.fromISO(b.date_debut));
        });
        liste.sort((a,b)=>b.year-a.year);
      } else if (['residence','rendez-vous'].indexOf(rubrique.type)!==-1) {
        rubrique.articles.forEach((article) => {
          const year=article.date ? DateTime.fromISO(article.date).toFormat('y') : '0000';
          const item=liste.find((o)=>o.year===year);
          if (item) item.liste.push(article);
          else liste.push({year,liste:[article]});
        });
        liste.forEach((year) => {
          year.liste.sort((a,b)=>DateTime.fromISO(a.date)-DateTime.fromISO(b.date));
        });
        liste.sort((a,b)=>b.year-a.year);
      }
    }
    return liste.filter((o)=>o.year!=='0000');
  },[rubrique]);
  return <div className="rubrique-liste-articles">
    {(rubrique.type===null || rubrique.type==='defaut') && rubrique.articles && rubrique.articles.length>0 && <Grid container spacing={2} className="rubrique-liste-articles-grid">
      {rubrique.articles.map((a,i)=>{
        return a.index ? '' : <Grid item xs={ rubrique.articles>=3 ? 4 : true } key={a.id} className="rubrique-liste-article-grid">
          <SlugLink item={a}>
          <div className="rubrique-liste-article">
            <div className="rubrique-liste-article-titre">
              <i>{translate(a,'name')}</i>
            </div>
          </div>
          </SlugLink>
        </Grid>;
      })}
    </Grid>}
    {liste.length>0 && <Grid container spacing={2}>
      <Grid item xs={4}>
        {liste[currentYear+1] && <div className="expo-chooser" onClick={()=>liste[currentYear+1] && setCurrentYear(currentYear+1)}>
          <span className="fleche-gauche"></span> {liste[currentYear+1].year}
        </div>}
      </Grid>
      <Grid className="expo-chooser choosen" item xs={4}>
        <div>
          {liste[currentYear].year}
        </div>
      </Grid>
      <Grid item xs={4}>
        {liste[currentYear-1] && <div className="expo-chooser" onClick={()=>liste[currentYear-1] && setCurrentYear(currentYear-1)}>
          {liste[currentYear-1].year} <span className="fleche-droite"></span>
        </div>}
      </Grid>
    </Grid>}
    {rubrique.type==='exposition' && liste.length>0 && <Grid container spacing={2} className="rubrique-liste-articles-grid">
      {liste[currentYear].liste.map((a,i)=>{
        return a.index ? '' : <Grid item xs={largeur} key={a.id} className="rubrique-liste-article-grid">
          <SlugLink item={a}>
          <div className="rubrique-liste-article">
            <div className="expo-tile">
              <div className="expo-tile-inner">
                <div className="rubrique-liste-article-titre">
                  <i>{translate(a,'name')}</i>
                </div>
                <div className="rubrique-liste-article-type">{translate(a,'soustitre')}</div>
                <div className="rubrique-liste-article-type">{DateTime.fromISO(a.date_debut).toFormat('d.LL')}>{DateTime.fromISO(a.date_fin).toFormat('d.LL')}</div>
              </div>
              {a.images.length>0 && <div className="rubrique-liste-article-image">
                <div className="image-mask"/>
                <Image src={`/assets/${a.images[0].directus_files_id.id}?key=mini`} alt=""/>
              </div>}
            </div>
          </div>
          </SlugLink>
        </Grid>;
      })}
    </Grid>}
    {['residence','rendez-vous'].indexOf(rubrique.type)!==-1 && liste.length>0 && <Grid container spacing={2} className="rubrique-liste-articles-grid">
      {liste[currentYear].liste.map((a,i)=>{
        return a.index ? '' : <Grid item xs={ liste[currentYear].liste.length>=3 ? 4 : true } key={a.id} className="rubrique-liste-article-grid">
          <SlugLink item={a}>
          <div className="rubrique-liste-article">
            <div className="rubrique-liste-article-titre">
              <i>{translate(a,'name')}</i>
            </div>
          </div>
          </SlugLink>
        </Grid>;
      })}
    </Grid>}
    {rubrique.type==='podcasts' && <PodcastsList rubrique={rubrique}/>}
  </div>;
}
export default ListeArticles;
