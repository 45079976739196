import React, { useRef, useEffect } from "react";
import './Swiper.scss'
import Image from 'components/misc/Image';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Utils from 'utils/Utils';

SwiperCore.use([Navigation, Autoplay, A11y]);

function MySwiper({images,size,onSwiper=()=>null,mix=false,align='center',thumbKey='sd'}) {
  const swiperRef=useRef(null);
  const handleTransitionEnd=(swiper)=>{
    if (swiper){
      swiper.params.speed = 1500;
      swiper.autoplay.start();
    }
  };
  const handleTouchStart=(swiper)=>{
    if (swiper){
      swiper.params.speed = 300;
    }
  };
  const handleSwiper=(swiper)=>{
    swiperRef.current=swiper;
    onSwiper(swiper);
  };
  useEffect(()=>{
    return ()=>{
      if (swiperRef.current) swiperRef.current.destroy();
    }
  },[])
  return <div className={'swiper-top-container'+(mix ? ' mix' : '')}>
    <div className="swiper-top-container-inner">
    {images && images.length>1 ?
    <Swiper
    speed={1500}
    loop
    autoplay={{
      delay: 3000,
    }}
    onTransitionEnd={handleTransitionEnd}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchStart}
    onSwiper={handleSwiper}
    >
      {images.map((diap,i)=>{
          const img=diap.directus_files_id;
          console.log(img);
          return <SwiperSlide key={img.id}>
            <Image
            alt={img.title}
            width={Utils.computeImgSize({img}).width}
            height={Utils.computeImgSize({img}).height}
            src={`/assets/${img.id}?key=${thumbKey}`}
            size={size}
            align={align}
            />
            {img.credits && <div className="credits">
              © {img.credits}
            </div>}
          </SwiperSlide>;
      })}
    </Swiper>
    :
    <Image
    alt=''
    width={Utils.computeImgSize({img:images[0].directus_files_id}).width}
    height={Utils.computeImgSize({img:images[0].directus_files_id}).height}
    src={`/assets/${images[0].directus_files_id.id}?key=${thumbKey}`}
    size={size}
    align={align}
    />}
    {images[0].directus_files_id.credits && <div className="credits">
      © {images[0].directus_files_id.credits}
    </div>}
    </div>
    </div>;
}
export default MySwiper;
