import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Email } from "react-obfuscate-email";

const nbsp=(text)=>{
  let res=text+'';
  res=res.replace(/[ ]+!/gm,"&nbsp;!");
  res=res.replace(/[ ]+\?/gm,"&nbsp;?");
  res=res.replace(/[ ]+:/gm,"&nbsp;:");
  res=res.replace(/[ ]+;/gm,"&nbsp;;");
  return res;
}
const truncateString = (str, num)=>{
  let tab=str ? str.split(' ') : [];
  if (tab.length <= num) {
    return str
  }
  return tab.slice(0, num).join(' ') + '...'
};
const computeImgSize= ({img,w,h}) => {
  const {width,height}=img;
  const res={width,height};
  if(w) {
    res.width=w;
    res.height=height*w/width;
  }
  if(h) {
    res.height=h;
    res.width=width*h/height;
  }
  return res;
}
const parseWithLinks = (text)=>{
  if (text) {
    let res=nbsp(text);
    const options = {
      replace: ({ type, data, name, attribs, children }) => {
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('mailto:')!==-1
        ) {
          const mail=attribs.href.replace('mailto:','');
          return <Email email={mail}></Email>;
        }
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('://')===-1
        ) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
      }
    };
    return parse(res, options);
  }
  return '';
}
const cumulativeOffset = (element)=>{
    let top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);
    return {
        top: top,
        left: left
    };
}
const jump = (h)=>{
    const node = document.getElementById(h);
    let top=0;
    if (node) {
        top=cumulativeOffset(node).top;
    }
    window.scrollTo(0, top-15);
}
const path2type = (path)=>{
    const tab=path.split('/');
    const last=tab[tab.length-1];
    const tabLast=last.split('-');
    return tabLast[0];
}
const translate = (item,lang,key) => {
    let res=item ? item[key] : '';
    if (item.translations) {
        const tr=item.translations.find((o)=>o.languages_code && o.languages_code.code===lang);
        if (tr && tr[key]) res=tr[key];
    }
    return res;
}

const utils={
    jump,
    cumulativeOffset,
    path2type,
    parseWithLinks,
    nbsp,
    translate,
    truncateString,
    computeImgSize,
}
export default utils;
