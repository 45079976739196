import React, { useContext } from 'react';
import 'index.css';
import './AudioPlayer.scss';
import ProgressBar from './ProgressBar';
import { SettingsContext } from 'Providers/SettingsProvider';
import Image from 'components/misc/Image';

function AudioPlayer ({uuid,titre,mini,carre=false,image=null, onClose}) {
    const { appState, setAppState, screenSize } = useContext(SettingsContext);
    const playerState=appState.playersStates[uuid] || {};
    const playing=Boolean(playerState.playing);
    const position=playerState.position || 0;
    const duration=playerState.duration || 0;
    const currentTime=playerState.currentTime || 0;
    const handlePlay=()=>{
      setAppState((state)=>{
        const p=state.playersControls[uuid] || {};
        const playing=true;
        const playingIdx=p.playingIdx ? p.playingIdx+1 : 1;
        return {...state,
          playersControls:{...state.playersControls,[uuid]:{...p,playing,playingIdx}},
          currentPlayer:{uuid,titre}
        }
      });
    }
    const handlePause=()=>{
      setAppState((state)=>{
        const p=state.playersControls[uuid] || {};
        const playing=false;
        const playingIdx=p.playingIdx ? p.playingIdx+1 : 1;
        return {...state,
          playersControls:{...state.playersControls,[uuid]:{...p,playing,playingIdx}},
          currentPlayer:{uuid,titre}
        }
      });
    }
    const handleTimeupdate=(currentTime)=>{
      setAppState((state)=>{
        const p=state.playersControls[uuid] || {};
        const currentTimeIdx=p.currentTimeIdx ? p.currentTimeIdx+1 : 1;
        return {...state,
          playersControls:{...state.playersControls,[uuid]:{...p,currentTime,currentTimeIdx}},
          currentPlayer:{uuid,titre}
        }
      });
    }
    let soundClass='son';
    if (playing) soundClass+=' playing';
    return carre ?
        <div className={"son-carre "+soundClass}>
          <div className="son-carre-inner">
            {image && <Image src={image} alt=""/>}
            <div className="rond-background"></div>
            <span className='play' onClick={handlePlay}></span>
            <span className='pause' onClick={handlePause}></span>
            <div className="son-progress">
              <ProgressBar
              currentTime={currentTime}
              position={position}
              duration={duration}
              onPositionUpdate={handleTimeupdate}
              />
            </div>
          </div>
        </div>
      : <div className={soundClass+(mini ? ' mini':'')}>
        <div className="son-controls">
            {screenSize==='xs' && <div className="mask"/>}
            <div className="son-titre-small">
              {titre}
              <div className='player-close' onClick={()=>{handlePause();onClose();}}></div>
            </div>
            <span onClick={handlePlay} className='play'></span>
            <span onClick={handlePause} className='pause'></span>
            <div className="son-progress">
              <ProgressBar
              currentTime={currentTime}
              position={position}
              duration={duration}
              onPositionUpdate={handleTimeupdate}
              />
            </div>
        </div>
    </div>;
}
export default AudioPlayer;
