import React from "react";
import './Pagination.scss'

function Pagination({page,nb,onPageChange}) {
  return <div className="pagination">
    {[...Array(nb).keys()].map((idx)=><span key={idx} onClick={()=>onPageChange(idx+1)} className={"bullet"+(page===idx+1 ? ' active' : '')}>
    </span>)}
  </div>;
}
export default Pagination;
