import React, { useEffect, useContext } from "react";
import { useQuery } from '@apollo/client';
import './Rubrique.scss'
import SubRoutes from "components/Misc/SubRoutes";
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';
import MenuRubriques from 'components/Rubrique/MenuRubriques';
import MenuArticles from 'components/Rubrique/MenuArticles';
import ListeArticles from 'components/Rubrique/ListeArticles';
import Container from '@material-ui/core/Container';
import Utils from 'utils/Utils';

function Rubrique({id, subRoutes, exact=false, pathname}) {
  const { updateLoadState,fetchedList,translate } = useContext(SettingsContext);
  const { networkStatus,data,refetch } = useQuery(Queries.rubrique,{
    variables: { id:parseInt(id) },
  });
  const rubrique=data ? data.rubriques[0] : null;
  let depth=0;
  const uuid=`rubrique${id}`;
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  useEffect(()=>exact ? document.title = (rubrique ? rubrique.name : '') : null,[rubrique,exact]);
  //console.log(id, data);
  const localRoutes=[];
  return rubrique ? <>
      {exact ?
        <>
        <div className="rubrique exact">
          {rubrique.texte && <Container maxWidth='lg'>
            <div className='rubrique-texte'>
              {Utils.parseWithLinks(translate(rubrique,'texte'))}
            </div>
          </Container>}
          <MenuRubriques rubrique={rubrique}/>
          <MenuArticles rubrique={rubrique}/>
          <ListeArticles rubrique={rubrique}/>
        </div>
        </>:
        <div className="rubrique">
          <SubRoutes id={rubrique.id} localRoutes={localRoutes} depth={depth+1}/>
        </div>
      }
      </> : <div><div className="bottom-padding"/></div> ;
}
export default Rubrique;
