import React, { useContext } from "react";
import {
  Link
} from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';

const SlugLink = React.forwardRef(({ item, children, className, forwardedRef, onMouseEnter }, ref) => {
  const { getPath, pathname, removeScrollY } = useContext(SettingsContext);
  const path=getPath(item);
  return <Link to={{pathname:path,state:{from:pathname, item}}} className={className} ref={ref || forwardedRef} onMouseEnter={onMouseEnter} onClick={()=>removeScrollY(path)}>{children}</Link>;
});

export default SlugLink;
