import React, {useContext} from "react";
import './MenuRubriques.scss'
import SlugLink from 'components/Misc/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';

function MenuRubriques({rubrique}) {
  const { translate } = useContext(SettingsContext);
  return <>
          {rubrique.rubriques && rubrique.rubriques.length>0 && <div className='menu-rubriques'>
            {rubrique.rubriques.map((r,i)=>{
              return <div className='menu-rubriques-item' key={r.id}>
                <div className="rubrique-menu-rubriques">
                  <div className="rubrique-menu-rubrique-titre">
                    <SlugLink item={r}>{translate(r,'name')}</SlugLink>
                  </div>
                  {false && r.rubriques.map((r,i)=>{
                    return <div key={r.id} className="rubrique-sousmenu">
                      <SlugLink item={r}>{translate(r,'name')}</SlugLink>
                    </div>;
                  })}
                  {false && r.articles.map((r,i)=>{
                    return <div key={r.id} className="rubrique-sousmenu">
                      <SlugLink item={r}>{translate(r,'name')}</SlugLink>
                    </div>;
                  })}
                </div>
              </div>;
            })}
          </div>}
        </>;
}
export default MenuRubriques;
