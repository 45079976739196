import React, {useContext} from "react";
import './Document.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';

function Document({bloc}) {
  const { translate } = useContext(SettingsContext);
  return <div className="bloc-document">
    <div className="bloc-document-star"><span></span></div>
    <h3>{translate(bloc,'texte_documents')}</h3>
    <div className="document-container">
    {bloc.documents && bloc.documents.map((doc,i)=>{
      const document=doc.directus_files_id;
      const url=`${Conf.apiUrl}/assets/${document.id}`;
      return document ? <div className="document-item" key={document.id}>
          <a href={url} target="_blank" rel="noreferrer">{document.title}</a>
      </div> : '';
    })}
    </div>
    <div className="bloc-document-star"><span></span></div>
  </div>;
}
export default Document;
