import React, {useState,useEffect,useContext} from "react";
import './Bloc.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import BlocImage from 'components/Article/Blocs/Image';
import BlocTexte from 'components/Article/Blocs/Texte';
import BlocDocument from 'components/Article/Blocs/Document';
import BlocCarte from 'components/Article/Blocs/Carte';

function Bloc({bloc,prevId,idx}) {
  const [ top,setTop ] = useState(0);
  const { width } = useContext(SettingsContext);
  useEffect(()=>{
    let top=0;
    if (['lg','xl','xxl'].indexOf(width)!==-1) {
      const textNode=document.getElementById(prevId);
      for(let i=0;i<idx;i++) {
        let node=textNode.parentNode.querySelector('.bloc'+i);
        if (node) top+=node.clientHeight+30;
      }
      top=Math.max(textNode ? textNode.offsetTop : 0,top);
    }
    setTop(top);
  },[width,prevId,idx]);
  return bloc ? <div className={"bloc bloc"+idx+(['lg','xl','xxl'].indexOf(width)!==-1 ? ' move-right' : '')} style={{top:top}}>
    {bloc.__typename==="bloc_image" && <BlocImage bloc={bloc}/>}
    {bloc.__typename==="bloc_texte" && <BlocTexte bloc={bloc}/>}
    {bloc.__typename==="bloc_document" && <BlocDocument bloc={bloc}/>}
    {bloc.__typename==="bloc_carte" && <BlocCarte bloc={bloc}/>}
  </div> : null;
}
export default Bloc;
