import React, {useEffect,useState} from "react";
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import NotFound from 'components/NotFound';
import Actualites from 'components/Actualites';
import Playlist from 'components/AudioPlayer/Playlist';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';
import SubRoutes from "components/Misc/SubRoutes";
import ScrollToTop from "components/Misc/ScrollToTop";
import SettingsProvider from "Providers/SettingsProvider";
const breakpoints={
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
const theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e1f0f7',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#016a66',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      // Name of the rule
      indicator: {
        // Some CSS
        height: 8,
      },
    },
  },
});

function App({subRoutes,clientSystem}) {
  const [ width,setWidth ]= useState('');
  useEffect(()=>{
    const setSize=(size)=>{
      let sizes=['xs','sm','md','lg','xl','xxl'].filter((o)=>o!==size);
      document.body.classList.remove(...sizes);
      document.body.classList.add(size)
      setWidth(size);
    }
    const resize=()=>{
      if(window.innerWidth>breakpoints.values.xxl) return setSize('xxl');
      if(window.innerWidth>breakpoints.values.xl) return setSize('xl');
      if(window.innerWidth>breakpoints.values.lg) return setSize('lg');
      if(window.innerWidth>breakpoints.values.md) return setSize('md');
      if(window.innerWidth>breakpoints.values.sm) return setSize('sm');
      if(window.innerWidth>breakpoints.values.xs) return setSize('xs');
    }
    window.addEventListener('resize',resize);
    resize();
    return ()=>{
      window.removeEventListener('resize',resize);
    }
  },[setWidth]);
  const localRoutes=[
    <Route key='home' exact component={Actualites} path='/' />,
    <Route key='notfound' component={NotFound} />,
  ]
  return (
      <ThemeProvider theme={theme}>
      <Router>
        <SettingsProvider clientSystem={clientSystem} width={width}>
          <Topbar/>
          <div className="routes-container">
            <SubRoutes root={true} localRoutes={localRoutes}/>
          </div>
          <Footer/>
          <Playlist/>
          <ScrollToTop/>
        </SettingsProvider>
      </Router>
      </ThemeProvider>
  );
}

export default App;
