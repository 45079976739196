import React, { useContext, useState, useRef } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import AudioPlayer from 'components/AudioPlayer';
import SlugLink from 'components/Misc/SlugLink';
import Swiper from 'components/misc/Swiper';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

function ArticlePodcast({article,siblings}) {
  const { translate, ready, screenSize } = useContext(SettingsContext);
  const idx= siblings ? siblings.findIndex((o)=>o.id===article.id) : 0;
  const isFirst=siblings ? siblings.findIndex((o)=>o.id===article.id) === 0 : true;
  const isLast=siblings ? siblings.findIndex((o)=>o.id===article.id) === siblings.length-1 : true;
  const next=!isLast && siblings ? siblings[idx+1] : null;
  const prev=!isFirst && siblings ? siblings[idx-1] : null;
  const [mix,setMix] = useState(true);
  const swiperRef=useRef(null);
  const swiperRefXs=useRef(null);
  const handleSwiper=(swiper)=>{
    swiperRef.current=swiper;
    swiper.on('slideChange', ()=>{
      setMix(false);
    });
  }
  const handleSwiperXs=(swiper)=>{
    swiperRefXs.current=swiper;
    swiper.on('slideChange', ()=>{
      setMix(false);
    });
  }
  let spacing=10;
  if (screenSize==='xs') spacing=2;
  return article ? <div className={'article article-podcast'+(ready ? ' ready' : '')}>
      <h3 className="article-titre">{translate(article,'name')}</h3>
      <Hidden lgUp>
        <div className="podcast-container podcast-container-xs">
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <AudioPlayer carre uuid={article.id} titre={translate(article,'name')} image={article.images[0] ? '/assets/'+article.images[0].directus_files_id.id+'?key=mini' : null}/>
          </Grid>
          <Grid item xs={6} className="podcast-container-fleches">
            <div className="fleches-container">
              {isFirst ? <div className="fleche-gauche">
                </div>
              : <SlugLink item={prev}>
                <div className="fleche-gauche active">
                </div>
              </SlugLink>}
              {isLast ? <div className="fleche-droite">
                </div>
              : <SlugLink item={next}>
                <div className="fleche-droite active">
                </div>
              </SlugLink>}
            </div>
          </Grid>
        </Grid>
        <div className="spacer"/>
        {article.images[0] && <div className="podcast-image-container">
          <div className="podcast-image-inner">
            <Swiper mix={mix} active={true} images={article.images} thumbKey='hd' onSwiper={handleSwiperXs}/>
          </div>
        </div>}
        <div className="article-texte" id={'texte-root-'+article.id}>{Utils.parseWithLinks(translate(article,'texte'))}</div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {article.intervenants && article.intervenants.length>0 && <div className="intervenants">
              {article.intervenants.map((intervenant,i)=><div key={i} className="intervenant">
                <div className="intervenant-nom">{intervenant.nom}</div>
                <div className="intervenant-bio">{intervenant.bio}</div>
              </div>)}
            </div>}
            {article.generique && article.generique.length>0 && <div className="generiques">
              {article.generique.map((item,i)=><div key={i} className="generique">
                <div className="generique-fonction">{item.fonction}</div>
                <div className="generique-nom">{item.nom}</div>
              </div>)}
            </div>}
          </Grid>
          <Grid item xs={12} sm={6}>
            {article.ressources && article.ressources.length>0 && <div className="ressources">
              {article.ressources.map((ressource,i)=><div key={i} className="ressource">
                <div className="ressource-surtitre">{ressource.surtitre}</div>
                <a href={ressource.url} target='_blank' rel="noreferrer">
                  <div className="ressource-titre">{ressource.titre}</div>
                </a>
              </div>)}
            </div>}
          </Grid>
        </Grid>

        </div>
      </Hidden>
      <Hidden mdDown>
      <div className="podcast-container">
        <div className="podcast-col-1">
          <AudioPlayer carre uuid={article.id} titre={article.name} image={article.images[0] ? '/assets/'+article.images[0].directus_files_id.id+'?key=mini' : null}/>
          {article.intervenants && article.intervenants.length>0 && <div className="intervenants">
            {article.intervenants.map((intervenant,i)=><div key={i} className="intervenant">
              <div className="intervenant-nom">{intervenant.nom}</div>
              <div className="intervenant-bio">{intervenant.bio}</div>
            </div>)}
          </div>}
          {article.generique && article.generique.length>0 && <div className="generiques">
            {article.generique.map((item,i)=><div key={i} className="generique">
              <div className="generique-fonction">{item.fonction}</div>
              <div className="generique-nom">{item.nom}</div>
            </div>)}
          </div>}
        </div>
        <div className="podcast-col-2">
          <div>
            <div className="podcast-col-2-1">
              {article.images[0] && <div className="podcast-image-container">
                <div className="podcast-image-inner">
                  <Swiper mix={mix} active={true} images={article.images} thumbKey='hd' onSwiper={handleSwiper}/>
                </div>
              </div>}
            </div>
            <div className="podcast-col-2-2 podcast-container-fleches">
              <div className="fleches-container">
                {isFirst ? <div className="fleche-gauche">
                  </div>
                : <SlugLink item={prev}>
                  <div className="fleche-gauche active">
                  </div>
                </SlugLink>}
                {isLast ? <div className="fleche-droite">
                  </div>
                : <SlugLink item={next}>
                  <div className="fleche-droite active">
                  </div>
                </SlugLink>}
              </div>
            </div>
          </div>
          <div>
            <div className="podcast-col-2-1">
              <div className="article-texte" id={'texte-root-'+article.id}>{Utils.parseWithLinks(translate(article,'texte'))}</div>
            </div>
            <div className="podcast-col-2-2">
              {article.ressources && article.ressources.length>0 && <div className="ressources">
                {article.ressources.map((ressource,i)=><div key={i} className="ressource">
                  <div className="ressource-surtitre">{ressource.surtitre}</div>
                  <a href={ressource.url} target='_blank' rel="noreferrer">
                    <div className="ressource-titre">{ressource.titre}</div>
                  </a>
                </div>)}
              </div>}
            </div>
          </div>
        </div>
      </div>
      </Hidden>
    <div className="bottom-padding"/>
    </div> : null;
}
export default ArticlePodcast;
