import React, { useState, useEffect, useContext, useRef, useCallback, useLayoutEffect } from "react";
import Container from '@material-ui/core/Container';
import './Article.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';
import Utils from 'utils/Utils';
import BlocTexte from 'components/Article/Blocs/Texte';
import BlocDocument from 'components/Article/Blocs/Document';
import Bloc from 'components/Article/Blocs';
import Mosaique from 'components/Article/Mosaique';
import {
  useLocation
} from "react-router-dom";
import Swiper from 'components/misc/Swiper';

function ArticleExposition({article}) {
  const { translate, t, setAppState, screenSize} = useContext(SettingsContext);
  const [passed,setPassed ] = useState(false);
  const [onScreen,setOnScreen ] = useState(false);
  const [overlay,setOverlay ] = useState(true);
  const [ready,setReady ] = useState(false);
  const [more,setMore ] = useState(false);
  const [needMore,setNeedMore ] = useState(false);
  const mainRef=useRef(null);
  const timeout=useRef(null);
  useEffect(()=>{
    return ()=>{
      if (timeout.current) clearTimeout(timeout.current);
    }
  },[]);
  const magnetic=useCallback(()=>{
    const node=mainRef.current;
    if (node!==null) {
      const {top}=node.getBoundingClientRect();
      if (top<150 && top>-150) {
        console.log('magnetic');
        node.scrollIntoView({behavior: "smooth"});
      }
      const {top:top2} = node.children[1].getBoundingClientRect();
      if (top2<150 && top2>-150) {
        node.children[1].scrollIntoView({behavior: "smooth"});
      }
    }
  },[mainRef]);
  const handleScroll=useCallback((e)=>{
    if (article && article.rubrique.type==='exposition' && article.images.length>0) {
      console.log('scroll');
      setAppState((state)=>{return {...state,menuFolded:false}});
      const node=mainRef.current;
      if (node!==null) {
        const {top}=node.getBoundingClientRect();
        if (top<0) setPassed(true);
        else setPassed(false);
        clearTimeout(timeout.current);
        timeout.current=setTimeout(magnetic,500);
        if (top>-5) setOnScreen(true);
        else setOnScreen(false);
      }
    }
  },[mainRef,setPassed,magnetic,article,setOnScreen,setAppState]);
  useEffect(()=>{
    if (passed) setOverlay(true);
  },[setOverlay,passed])
  const {pathname,state} = useLocation();
  useLayoutEffect(()=>{
    window.addEventListener('scroll',handleScroll);
    handleScroll();
    return ()=>window.removeEventListener('scroll',handleScroll);
  },[handleScroll]);
  useLayoutEffect(()=>{
    if (article && article.images.length>0) {
      let interval=setInterval(()=>{
        const node=mainRef.current;
        if (node && document.querySelector('.top-container').clientHeight+100>=node.children[0].clientHeight+node.children[1].clientHeight) {
          clearInterval(interval);
          if (state && state.from==='/') {
            node.children[1].scrollIntoView();
          }
          else {
            node.children[0].scrollIntoView();
            setOnScreen(true);
          }
          if(document.querySelector('.article-texte').clientHeight===600) setNeedMore(true);
          setReady(true);
        }
      },100);
    } else {
      setReady(true);
    }
  },[mainRef,pathname,state,article,setOnScreen]);
  useLayoutEffect(()=>{
    const handleResize=()=>{
      if(document.querySelector('.article-texte').clientHeight===600) setNeedMore(true);
      else setNeedMore(false);
    }
    window.addEventListener('resize',handleResize);
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[]);
  useEffect(()=>{
    window.dispatchEvent(new Event('scroll'));
  },[more]);
  let prevId=article ? 'texte-root-'+article.id : null;
  if (article) {
      return <div className={"article article-exposition"+(overlay ? ' overlay' : '')+(needMore ? ' need-more' : '')+(more ? ' more' : '')+(passed ? ' passed' : '')+(ready ? ' ready' : '')+(onScreen ? ' on-screen' : '')} ref={mainRef}>
      <div className="article-visuel-preview">
      {article.images && article.images.length>0 && <Swiper mix={true} active={onScreen} images={[article.images[0]]} thumbKey='hd'/>}
      </div>
      {article.images.length>0 && <div className="article-content">
            <div>
            <div className="article-content-header">
            <h3 className="article-titre"><i>{translate(article,'name')}</i></h3>
            <h3 className="article-artiste">{translate(article,'soustitre')}</h3>
            </div>
            </div>
      <Container maxWidth='xl'>
        <div className="article-intro">{Utils.parseWithLinks(translate(article,'intro'))}</div>
      </Container>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Mosaique images={article.images} wide={['xs','sm','md'].indexOf(screenSize)!==-1}/>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="article-expo-droite">
              <div className="article-texte">
                {Utils.parseWithLinks(translate(article,'texte'))}
                <div className="view-more"></div>
              </div>
              <div className="view-more-btn">
                <span onClick={()=>setMore(true)}>{t('voir-plus')}</span>
              </div>
              <div className="view-less-btn">
                <span onClick={()=>setMore(false)}>{t('voir-moins')}</span>
              </div>
            </div>
          </Grid>
        </Grid>
        {article.documents.length>0 && <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <BlocDocument bloc={article}/>
          </Grid>
        </Grid>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {article.blocs.length>0 && <Container maxWidth='lg'>
              {article.blocs.map((bloc,i)=>{
                if (bloc.item.__typename==="bloc_texte") prevId='texte-'+i+'-'+article.id;
                if (bloc.item.__typename==="bloc_texte") return <BlocTexte key={bloc.id} prevId={prevId} bloc={bloc.item}/>;
                return <Bloc key={bloc.id} prevId={prevId} bloc={bloc.item}/>;
              })}
            </Container>}
          </Grid>
        </Grid>
      </Container>
      </div>}
      {article.images.length===0 && <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3 className="article-titre">{article.name}</h3>
            <div className="article-texte">
              {Utils.parseWithLinks(translate(article,'texte'))}
            </div>
          </Grid>
        </Grid>
      </Container>}
      <div className="bottom-padding"/>
      </div>;
  } else return <div><div className="bottom-padding"/></div>;
}
export default ArticleExposition;
