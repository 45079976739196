import React, { useContext,useRef,useEffect } from "react";
import './PodcastsList.scss'
import Grid from '@material-ui/core/Grid';
import SlugLink from 'components/Misc/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';
import Image from 'components/misc/Image';
import _ from 'lodash';

function PodcastsList({rubrique}) {
  const { translate, screenSize } = useContext(SettingsContext);
  const deltas=useRef({});
  useEffect(()=>{
    rubrique.articles.forEach((article, i) => {
      if (!deltas.current[article.id]) deltas.current[article.id]=(Math.random()+i%2)*50;
    });
  },[rubrique]);
  const annees=_.groupBy(rubrique.articles,(o)=>o.date ? o.date.substring(0,4) : '0000');
  let spacing=10;
  if (screenSize==='xl') spacing=6;
  if (screenSize==='lg') spacing=5;
  if (screenSize==='xs') spacing=2;
  //console.log(annees);
  return <div className="podcasts-liste">
    <div className="podcasts-liste-titre">
      <i>{translate(rubrique,'name')}</i>
    </div>
    {Object.keys(annees).map((annee)=><div key={annee} className="podcasts-annee">
      <div className="podcasts-liste-annee">
      {annee}
      </div>
      <Grid container spacing={spacing} className="podcasts-tiles">
        {annees[annee].map((a)=><Grid item xs={6} md={4} lg={3} key={a.id}><div className="podcast-tile" style={{top:deltas.current[a.id]+'px'}}>
          <SlugLink item={a}>
          <div className="podcast-tile-inner">
            {a.images.length>0 ? <div className="podcast-tile-image">
              <div className="image-mask"/>
              <Image src={`/assets/${a.images[0].directus_files_id.id}?key=mini`} alt=""/>
              <div className="podcast-tile-titre">
                <div>{translate(a,'name')}</div>
              </div>
            </div> : <div className="podcast-tile-image">
              <div className="image-mask"/>
              <div className="podcast-tile-titre">
                <div>{translate(a,'name')}</div>
              </div>
            </div>}
          </div>
          </SlugLink>
        </div>
        </Grid>)}
      </Grid>
    </div>)}
  <div className="bottom-padding"/>
  </div>;
}
export default PodcastsList;
