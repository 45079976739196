import React, {useContext} from "react";
import './Ariane.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Misc/SlugLink';

function Ariane({lastOnly=false}) {
  const { ariane,translate } = useContext(SettingsContext);
  const filtered=ariane.filter((o)=>o.__typename==='rubriques');
  return ariane.length>0 ? <div className="ariane">
    {filtered.filter((o,i)=>!lastOnly || (lastOnly && i===filtered.length-1)).map((item,i)=><React.Fragment key={i}>
      <span>{i>0 && ' >'}<SlugLink item={item}>{translate(item,'name')}</SlugLink></span>
    </React.Fragment>)}
  </div> : null;
}
export default Ariane;
